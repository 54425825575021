
export default {
  props: ["bannerImg", "height"],
  data() {
    return {
      index: 1,
      img: null,
    };
  },
  methods: {
    ChangeImg() {
      var me = this;
      setInterval(() => {
        if (me.index === me.bannerImg.length - 1) {
          me.img = me.bannerImg[me.index].img;
          me.index = 0;
        } else {
          me.img = me.bannerImg[me.index].img;
          me.index += 1;
        }
      }, 10000);
    },
  },
  mounted() {
    this.img = this.bannerImg[0].img;
    this.ChangeImg();
  },
};
